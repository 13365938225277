import i1 from './1.jpg'
import i2 from './2.jpg'
export const vibratingJumpersAndFeet = [
    {
        "name": "BOMAG BT60/4",
        "url": "bomag",
        "image_src": i2,
        "spec": ["Masa(kg): 62",
            "Siła wymuszająca(kN): 13,5",
            "Częstotliwość wibracji(1/min): 600-708",
            "Szerokość robocza(mm): 280",
            "Silnik: Honda",
            "Typ silnika: GX100",
            "Moc silnika(KM/kW): 3,0/2,2",
            "Obroty silnika(obr/min): 4200",
            "Rozruch: ręczny",
            "Zbiornik paliwa(litry): 3"],
        "price_4h": 0,
        "price_1d": 100
    },
    {
        "name": "WACKER BS 65 V",
        "url": "wacker",
        "image_src": i1,
        "spec": ["Waga: 68,0 kg",
            "Max. częstotliwość udaru: 700 1/min.",
            "Skok stopy ubijaka: 8,0 - 81,0 mm",
            "Prędkość liniowa: 9,5 m / min",
            "Wymiary płyty: 280 x 337 mm",
            "Max. wydajność: 160 m2 / h",
            "Typ silnika: dwusuwowy, chłodzony powietrzem, poziomy wał",
            "Moc: 2,5 KM (4400 obr-1)",
            "Zyżycie paliwa: 0,9 l/h",
            "Pojemność zbiornika paliwa: 3,0 l"],
        "price_4h": 0,
        "price_1d": 100
    }
]
