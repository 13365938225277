import kaaz_ag500 from './kaaz_ag500.jpg'
export const soilDrillingRigs = [
    {
        "name": "KAAZ AG500",
        "url": "kaaz_ag500",
        "image_src": kaaz_ag500,
        "short_description": "",
        "spec": [
            "Prędkość obrotowa noży: 170 obr./min",
            "Wiertła do wyboru: 150/200/250 mm",
            "Moc: 1,8 KW/2,44KM",
            "Ciężar bez wiertła: 9,3 kg"
        ],
        "price_4h": 0,
        "price_1d": 120
    }
//    {
//        "name": "Tanaka TEA-500",
//        "url": "tanaka_tea-500",
//        "image_src": tanaka,
//        "spec": [
//            "Moc silnika (kW/KM): 1,54",
//            "Pojemność skokowa silnika (cm3): 50,2",
//            "Max średnica wiercenia: 300",
//            "Waga (kg): 17",
//            "Wymiary DxWxS (mm): 1200 x 600 x 490",
//            "Pojemność zbiornika paliwa (l): 1,0"],
//        "price_4h": 0,
//        "price_1d": 150
//    },
]
