import React, {Component} from 'react';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import '../../resources/styles/menu.scss'
import Box from "@material-ui/core/Box";
import {urls} from "../../const/Urls";
import {withRouter} from "react-router-dom";

class Menu extends Component {

    render() {
        return (
            <Box className='menuBox'>
                <ButtonGroup variant="text">
                    <Button
                        className={this.props.location.pathname === urls.homepage ? 'menuButtonActive' : 'menuButton'}
                        href={urls.homepage}>Strona główna</Button>
                    <Button
                        className={this.props.location.pathname === urls.catalog ? 'menuButtonActive' : 'menuButton'}
                        href={urls.catalog}>Katalog</Button>
                    <Button
                        className={this.props.location.pathname === urls.contact ? 'menuButtonActive' : 'menuButton'}
                        href={urls.contact}>Kontakt</Button>
                </ButtonGroup>
            </Box>
        )

    }
}

export default withRouter(Menu);
