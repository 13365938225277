import cedrus_rb04pro from './cedrus_rb04pro.jpg'
export const chippers =[
  {
    "name": "Cedrus RB04PRO",
    "url": "cedrus_rb04pro",
    "image_src": cedrus_rb04pro,
    "spec": [
        "Prędkość obrotowa noży: 2000obr./min",
        "Pojemność zbiornika oleju: 1,1 l",
        "Max wydajność: 5m3/h – 7m3/h",
        "Max moc silnika: 12,5 KM",
        "Rozmiar kół (przód/tył): 16x6.5-8 / 10x4.1-4",
        "Dodatkowe zalety: pompowane koła, zaczep samochdowy, regulawany komin, wyłączniki bezpieczeństwa",
        "Łożyska kół: Tak",
        "Ostrze tnące: 2 x 300mm",
        "Przekazanie napędu: 2 paski klinowe",
        "Średnica gałęzi 150mm",
        "Rozrusznik: elektryczny / ręczny",
        "Pojemność zbiornika paliwa: 6,5 l",
        "Ciężar: 260kg kg"
    ],
    "price_4h": 0,
    "price_1d": 200
  }
]
