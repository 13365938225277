import i1 from './1.jpg'
export const vibratingBars = [
    {
        "name": "Elektryczna - 2m",
        "url": "2m",
        "image_src": i1,
        "spec": [
            "Typ: listwa wygładzająca",
            "Typ silnika: elektryczny",
            "Długość: 2m",
            "Listwa robocza z wytłaczanego stopu aluminium odporna na odkształcenia",
            "Wygodna w obsłudze rękojeść z przegugem przechylnym do zmiany kąta nachylenia listwy",
            "Listwa elektryczna nie wymaga prowadnic",
            "Podwójnie izolowany silnik elektryczny 230 V"
        ],
        "price_4h": 0,
        "price_1d": 70
    }
]
