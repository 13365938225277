import React from 'react'
import {GoogleMap, InfoWindow, Marker, useJsApiLoader} from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '450px',
    border: '2px solid #e84c0e'
};

const center = {
    lat: 51.684204,
    lng: 19.293861
};

function GoogleMapsWrapper() {
    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyCQFnQz26On_7wRUBN9vEuBRx3gW0pIE8E"
    })

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={15}
        >
            <Marker position={{lat: 51.683384, lng: 19.293861}}>
                <InfoWindow position={{lat: 51.684315, lng: 19.293861}}>
                    <div>
                    <div style={{color: "#333", fontFamily: 'Arial', fontSize: "14px", fontWeight: 600}}>KNOP-MAR<br/>WYPOŻYCZALNIA ELEKRONARZĘDZI, SPRZĘTU BUDOWLANEGO I OGRODNICZEGO</div>
                    <div style={{color: "#333", fontFamily: 'Roboto,Arial', fontSize: "13px"}}>Kudrowice 35G<br/>95-200 Pabianice</div>
                    <a href="https://maps.google.com/maps?ll=51.683204,19.293861&z=15&t=m&hl=pl&gl=US&mapclient=apiv3&cid=9978093547935575412">Zobacz w Mapach Google</a>

                    </div>
                                   </InfoWindow>
            </Marker>

            <></>
        </GoogleMap>
    ) : <></>
}

export default React.memo(GoogleMapsWrapper)
