import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import Header from "./shared/Header";
import Menu from "./shared/Menu";
import Footer from "./shared/Footer";
import '../resources/styles/contact.scss'
import GoogleMapsWrapper from "./GoogleMapsWrapper";
import Box from "@material-ui/core/Box";

class Contact extends Component {

    render() {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Header/>
                </Grid>
                <Grid item xs={12}>
                    <Menu/>
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row"
                          justifyContent="space-around"
                          alignItems="center" style={{padding: "20px"}}>
                        <Grid item xs={12} sm={2} style={{padding: "10px"}}>
                            <Box className="companyName"><b>KNOP-MAR</b></Box>
                            <Box className="companyLocation"><i><b>Kudrowice 35G,<br/>
                                95-200 Pabiancie<br/>
                                +48 608341601<br/>
                                knop-mar@wp.pl</b></i></Box>
                        </Grid>
                        <Grid item xs={12} sm={8} style={{padding: "20px"}}>
                            <GoogleMapsWrapper/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Footer/>
                </Grid>
            </Grid>
        )

    }
}

export default Contact;
