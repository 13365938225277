import i1 from './1.jpg'
import atlas_copco_48e from './atlas_copco_48e.jpg'
export const concreteVibrators = [
    {
        "name": "Wibrator (2000W)",
        "url": "2000w",
        "image_src": i1,
        "spec": [
            "Moc: 2000W",
            "Prędkość obrotowa silnika: 16000obr/min",
            "Buława wibracyjna (dł./średn.): 4m/42mm",
            "Wydajność: 20m³/godz.",
            "Zasilanie: 230V",
            "Waga napędu: 6kg",
            "Waga buławy: 10kg"
        ],
        "price_4h": 0,
        "price_1d": 80
    },
    {
        "name": "ATLAS COPCO SMART 48 E",
        "url": "atlas_copco_smart_48e",
        "image_src": atlas_copco_48e,
        "spec": [
            "Prędkość obrotowa silnika: 12300obr/min",
            "Buława wibracyjna (dł./średn.): 15m/48mm",
            "Wydajność: 20m³/godz.",
            "Zasilanie: 230V"
        ],
        "price_4h": 0,
        "price_1d": 80
    }
]
