import i1 from './1.jpg'
export const sprayers = [
    {
        "name": "Cifarelli M1200",
        "url": "cifarelli_m1200",
        "image_src": i1,
        "spec": [
            "Moc silnika: 3,6kW",
            "Szerokość robocza: 50 cm",
            "Głębokość robocza: 0-3 cm",
            "Zalecana powierzchnia pracy: do 2000 m2",
            "Waga: 65 kg"
        ],
        "price_4h": 0,
        "price_1d": 80
    }
]
