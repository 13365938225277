import master_bv77e from './master_bv77e.jpg'
import master_b_95_cel from './master_b_95_cel.jpg'
import master_b3_3_epb from './master_b3_3_epb.jpg'
import master_b5epb from './master_b5epb.jpg'
import master_b9epb from './master_b9epb.jpg'
import master_b180 from './master_b180.jpg'

export const heaters = [
    {
        "name": "Nagrzewnica olejowa Master BV 77 E",
        "url": "master_bv77e",
        "image_src": master_bv77e,
        "spec": ["Moc grzewcza [kW]: 21,0",
            "Przepływ powietrza [m³/h]: 1550",
            "Zużycie paliwa [l/h]: 2,0",
            "Zalecany do powierzchni [m²]: 210",
            "Moc grzewcza [kcal/h]: 18100",
            "Wydajność [Btu/h]: 71700",
            "Minimalny czas pracy na pełnym zbiorniku [h]: 19",
            "Napięcie [V]: 230 / 1-fazowe",
            "Częstotliwość [Hz]: 50",
            "Pobór mocy [kW]: 0,30",
            "Prąd znamionowy [A]: 1,5",
            "Pojemność zbiornika [l]: 36,0",
            "Średnica przyłącza kominowego [mm]: 120",
            "Średnica rury wylotowej [mm]: 230",
            "Regulacja termostatem: możliwa",
            "Rodzaj paliwa: olej opałowy / napędowy",
            "Zapłon: elektroda",
            "Rodzaj wentylatora: osiowy",
            "Typ nagrzewnicy olejowej: wysokociśnieniowa",
            "Stopień ochrony: IP 41",
            "Wyposażenie: kółkauchwyt do przenoszeniatermostat przegrzaniazbiornik paliwa",
            "Szerokość [cm]: 38,0",
            "Głębokość [cm]: 113,0",
            "Wysokość [cm]: 66,0",
            "Waga [kg]: 33,0"],
        "price_4h": 0,
        "price_1d": 70
    },
    {
        "name": "Nagrzewnica olejowa MASTER B 95 CEL",
        "url": "master_b95cel",
        "image_src": master_b_95_cel,
        "spec": ["moc grzewcza: 29 kW/99300 Btu/h/25000 kcal/h ",
            "regulacja mocy: brak",
            "przepływ powietrza: 800 m³/h ",
            "termostat pomieszczeniowy: brak ",
            "paliwo: olej napędowy/opałowy ",
            "zużycie paliwa: 2,3 kg/h ",
            "pojemność zbiornika paliwa: 44 l ",
            "czas pracy na pełnym zbiorniku: 16 h ",
            "odprowadzanie spalin: nie ",
            "wentylator: osiowy ",
            "temperatura wydmuchu w odległości 20cm: 250 °C ",
            "zapłon: żarnik elektroniczny ",
            "palnik: zintegrowany / wewnętrzny",
            "Napięcie: 220-240 V",
            "Częstotliwość: 50-60 Hz",
            "Prąd znamionowy: 1,0 A ",
            "Moc elektryczna: 0,23 kW",
            "Stopień ochrony: IP 41",
            "Waga: 25 kg",
            "Szerokość: 48,0 cm ",
            "Długość: 107,5 cm ",
            "Wysokość: 60,0 cm",
            ""],
        "price_4h": 0,
        "price_1d": 50
    },
    {
        "name": "Nagrzewnica elektryczna MASTER B 3,3 EPB",
        "url": "master_b3.3epb",
        "image_src": master_b3_3_epb,
        "spec": ["Moc grzewcza: 1,65 / 3,3 kW, 5630 / 11260 Btu/h, 1430 / 2860 kcal/h",
            "Regulacja mocy: stopniowa",
            "Przepływ powietrza: 510 m³/h",
            "Termostat pomieszczeniowy: wbudowany",
            "Poziom hałasu: 56 dB(A)",
            "Zakres temperatur: od +5 do +35 °C",
            "Paliwo: elektryczność",
            "Wtyczka (męska) od strony nagrzewnicy: 16A/3P",
            "Ustawienie przełącznika 1: wyłączony",
            "Ustawienie przełącznika 2: wentylator ",
            "Ustawienie przełącznika 3: 1,65 kW",
            "Ustawienie przełącznika 4: 3,3 kW",
            "Temperatura wydmuchu w odległości 20cm : 36 °C",
            "Napięcie: 230 V",
            "Częstotliwość: 50-60 Hz",
            "Prąd znamionowy: 14,5 A",
            "Szerokość: 27 cm",
            "Długość: 28 cm",
            "Wysokość: 44 cm",
            "Waga: 5,1 kg",
            "Stopień ochrony: IP 24"],
        "price_4h": 0,
        "price_1d": 20
    },
    {
        "name": "Nagrzewnica elektryczna MASTER B 5 EPB",
        "url": "master_b5epb",
        "image_src": master_b5epb,
        "spec": ["Moc grzewcza: 2,5 / 5,0 kW, 8530 / 17000 Btu/h, 2150 / 4300 kcal/h",
            "Regulacja mocy: stopniowa",
            "Przepływ powietrza: 510 m³/h",
            "Termostat pomieszczeniowy: wbudowany",
            "Poziom hałasu: 56 dB(A)",
            "Zakres temperatur: od +5 do +35 °C",
            "Paliwo: elektryczność",
            "Nagrzewnica elektryczna: wtyczka (męska) od strony nagrzewnicy: 16A/5P",
            "Ustawienie przełącznika 1: wyłączony",
            "Ustawienie przełącznika 2: wentylator",
            "Ustawienie przełącznika 3: 2,5 kW",
            "Ustawienie przełącznika 4: 5,0 kW",
            "Temperatura wydmuchu w odległości 20cm : 57 °C",
            "Napięcie: 400 V ",
            "Częstotliwość: 50-60 Hz ",
            "Prąd znamionowy: 7,2 A",
            "Szerokość: 36 cm",
            "Długość: 31 cm",
            "Wysokość: 38 cm",
            "Waga: 6,4 kg",
            "Stopień ochrony: IP 24"],
        "price_4h": 0,
        "price_1d": 30
    },
    {
        "name": "Nagrzewnica elektryczna MASTER B 9 EPB",
        "url": "master_b9epb",
        "image_src": master_b9epb,
        "spec": ["Moc grzewcza: 4,5/9,0 kW, 15350/30700 Btu/h 3870/7740 kcal/h",
            "Regulacja mocy: stopniowa",
            "Przepływ powietrza: 800 m³/h",
            "Termostat pomieszczeniowy: wbudowany",
            "Poziom hałasu: 55 dB(A)",
            "Zakres temperatur: od +5 do +35 °C",
            "Paliwo: elektryczność",
            "Nagrzewnica elektryczna: wtyczka (męska) od strony nagrzewnicy: 16A/5P",
            "Ustawienie przełącznika 1: wyłączony",
            "Ustawienie przełącznika 2: wentylator",
            "Ustawienie przełącznika 3: 4,5 kW",
            "Ustawienie przełącznika 4: 9,0 kW",
            "Temperatura wydmuchu w odległości 20cm : 56 °C",
            "Napięcie: 400 V",
            "Częstotliwość: 50-60 Hz",
            "Prąd znamionowy: 13 A",
            "Szerokość: 42 cm",
            "Długość: 34 cm",
            "Wysokość: 44 cm",
            "waga: 9,3 kg",
            "stopień ochrony: IP 24"],
        "price_4h": 0,
        "price_1d": 40
    },
    {
        "name": "Nagrzewnica elektryczna MASTER B180",
        "url": "master_b180",
        "image_src": master_b180,
        "spec": [
            "Moc grzewcza: 48 kW",
            "Przepływ powietrza: 1550 m³/h",
            "Zużycie paliwa: 4,5 l/h",
            "Pojemność zbiornika: 44 l",
            "Regulacja termostatem: możliwa",
            "Zalecany do powierzchni: 480 m²"
        ],
        "price_4h": 0,
        "price_1d": 60
    }

]
