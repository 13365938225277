import i2 from './2.jpg'
import fogo_fh6001r from './fogo_fh6001r.jpg'
import ep12000e from './ep12000e.jpg'
export const generators = [
    {
        "name": "1-fazowy 4kW",
        "url": "1_4kw",
        "image_src": i2,
        "spec": [
            "Moc znamionowa: 4,0 kW",
            "Prąd znamionowy: 18,2 A",
            "Napięcie: 230 V",
            "Częstotliwość: 50 Hz",
            "Ciężar: 60 kg",
            "Typ prądnicy: synchroniczna",
            "Stopień ochrony: IP 23",
            "Silnik: HONDA GX 270",
            "Moc przy 3000 obr. / min.: 5,7 kW",
            "Rodzaj paliwa: benzyna bezołowiowa 95",
            "Pojemność zbiornika paliwa: 5,3 l",
            "Rozruch: rewersyjny linką"
        ],
        "price_4h": 0,
        "price_1d": 70
    },
       {
            "name": "1-fazowy 6kW AVR FOGO FH6001R",
            "url": "fogo_fh6001r",
            "image_src": fogo_fh6001r,
            "spec": [
                "Moc nominalna 1-fazowa: 5,6 kW / 5,6 kVA",
                "Moc maksymalna 1-fazowa: 6,2 kW / 6,2 kVA",
                "Napięcie zasilania: 230V",
                "Prąd znamionowy: 24,3A",
                "Prądnica: synchroniczna",
                "Stopień ochrony: IP23",
                "Silnik: Honda GX390",
                "Moc silnika: 7,4 kW",
                "Rodzaj paliwa: benzyna bezołowiowa PB95",
                "Chłodzenie silnik/prądnica: powietrze/powietrze",
                "Rozruch: ręczny",
                "Poziom hałasu: 97 dB A",
                "Poj. zbiornika: 6,2l",
                "Zużycie paliwa: 2,7 l/h",
                "Waga (kg): 70",
                "Wymiary (axbxc): 79x59x54 cm"
            ],
            "price_4h": 0,
            "price_1d": 80
        },
    {
        "name": "EUROPOWER EP12000E 3-fazowy 12kW",
        "url": "europower",
        "image_src": ep12000e,
        "spec": [
            "Moc max.: 12 kW",
            "Moc nom.: 10,8 kW",
            "Gniazda AC: 1 x 230 V 16 A, 1 x 230 V 32 A",
            "Stabilizacja napięcia: brak",
            "Prąd znamionowy [A] 230 / 400V: 47,0 / -",
            "Gniazda DC: brak",
            "LWA /Stopień ochrony: - / ip23",
            "Rodzaj silnika: GX690",
            "Moc max. (norma SAE J1349): 22,1 KM",
            "Rozruch-ręczny/elektryczny: elektryczny",
            "Długość: 1020 mm",
            "Szerokość: 550 mm",
            "Wysokość: 600 mm",
            "Masa sucha: 150 kg",
            "Zbiornik paliwa: 20 l",
            "Czas pracy: Do 4h (przy 75% mocy znamionowej agregatu)",
            "Wyposażenie standardowe: licznik motogodzin, alarm olejowy, SZR"
        ],
        "price_4h": 0,
        "price_1d": 140
    }
]
