import i54_70 from './54_70.jpg'
import i100 from './100.jpg'
import i150 from './150.jpg'
import i240 from './240.jpg'
import ammann_apr_5920 from './ammann_apr_5920.jpg'
import bomag from './bomag.jpg'
import dpu4045yeh from './dpu4045yeh.webp'

export const compactors = [
    {
        "name": "54kg",
        "url": "54kg",
        "image_src": i54_70,
        "spec": ["Masa: 54 kg",
            "Szerokość płyty: 330 mm",
            "Silnik: HONDA GX 100",
            "Siła wymuszająca: 10 kN",
            "Częstotliwość wobtacji: 100 Hz",
            "Wyposażenie dodatkowe: płyta do kostki"],
        "price_4h": 0,
        "price_1d": 70
    },
    {
        "name": "70kg",
        "url": "70kg",
        "image_src": i54_70,
        "spec": ["Masa: 70 kg",
            "Szerokość płyty: 400 mm",
            "Długość płyty: 540 mm",
            "Silnik: HONDA",
            "Częstotliwość wobtacji: 98 Hz",
            "Wyposażenie dodatkowe: płyta do kostki"],
        "price_4h": 0,
        "price_1d": 70
    },
    {
        "name": "100kg",
        "url": "100kg",
        "image_src": i100,
        "spec": ["Masa: 100 kg",
            "Szerokość płyty: 500 mm",
            "Silnik: HONDA GX 160",
            "Siła wymuszająca: 15 kN",
            "Częstotliwość wibracji: 97 Hz",
            "Rolka transportowa",
            "Wyposażenie dodatkowe: płyta do kostki"],
        "price_4h": 0,
        "price_1d": 80
    },
    {
        "name": "150kg",
        "url": "150kg",
        "image_src": i150,
        "spec": ["Masa: 150 kg",
            "Szerokość płyty: 400mm",
            "Silnik: diesel",
            "Siła odśrodkowa: 24 kN",
            "Częstotliwość wibracji: 90 Hz",
            "Zagęszczarka dwukierunkowa"],
        "price_4h": 0,
        "price_1d": 120
    },
    {
        "name": "240kg",
        "url": "240kg",
        "image_src": i240,
        "spec": ["Masa: 240 kg",
            "Silnik: HATZ diesel",
            "Siła odśrodkowa: 36 kN",
            "Częstotliwość wibracji: 65 Hz",
            "Wymiar płyty: 500x700 mm",
            "Amplituda: 1,9 mm",
            "Rozruch: elektryny - ręczny",
            "Prędkość: 25 m/min"],
        "price_4h": 0,
        "price_1d": 140
    },
    {
        "name": "375kg (Wacker Neuson DPU4045Yeh)",
        "url": "dpu4045yeh",
        "image_src": dpu4045yeh,
        "spec": [
            "Szerokość x wysokość: 600 x 764 mm",
            "Typ silnika: spalinowy (diesel)",
            "Producent i model silnika: yanmar l70n",
            "Pojemność zbiornika paliwa: 3,3 l",
            "Zużycie paliwa: 1,4 l/h",
            "Częstotliwość: 69 hz",
            "Grubość płyty roboczej: 12 mm",
            "Pojemność skokowa: 320 cm³",
            "Siła odśrodkowa: 40 kn",
            "Prędkość robocza: 24 m/min (w zależności od gruntu i od czynników zewnętrznych)",
            "Maksymalna wydajność powierzchniowa: 870 m²/h",
            "Ciężar: 376 kg",
            "Rozruch: elektryczny",
            "Moc: 4,5 kw / 6,1 km",
            "Szerokość płyty roboczej: 440 mm",
            "Długość płyty roboczej: 900 mm",
            "Szerokość robocza: 600 mm (z płytą poszerzającą)",
            "Długość x szerokość x wysokość: 1514 x 1048 x 780 (transportowa)"
        ],
        "price_4h": 0,
        "price_1d": 180
    },
    {
        "name": "475kg (AMMANN APR 5920)",
        "url": "ammann_apr_5920",
        "image_src": ammann_apr_5920,
        "spec": [
            "typ: zagęszczarka dwukierunkowa",
            "waga [kg]: 475",
            "siła wymuszająca [kN]: 59",
            "częstotliwość [Hz]: 65",
            "typ silnika: Diesel",
            "model silnika: Hatz 1B40",
            "moc silnika [kW]: 6.60",
            "moc silnika [KM]: 9",
            "rozruch: ręczny i elektryczny",
            "prędkość robocza [m/min]: 33",
            "szerokość robocza [mm]: 600"
        ],
        "price_4h": 0,
        "price_1d": 200
    },
    {
        "name": "570kg (BOMAG BPR70/70D)",
        "url": "bomag_bpr70_70d",
        "image_src": bomag,
        "spec": [
            "Masa: 570 kg",
            "Wydajność: 9,3 kW",
            "Rozrusznik elektryczny",
            "Drążek sterowniczy o niskim poziomie drgań",
            "Drążek sterowniczy z regulacją wysokości",
            "Drążek sterowniczy z blokadą w pozycji transportowej i roboczej",
            "Regulacja drgań i położenia przepustnicy na drążku"
        ],
        "price_4h": 0,
        "price_1d": 230
    }
]
