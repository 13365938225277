import React, {Component} from 'react';
import {Box, CircularProgress} from "@material-ui/core";
import '../../resources/styles/slider.scss'
import '../../resources/styles/shared.scss'
import slider2 from '../../resources/images/slider/slider2.png'
import slider3 from '../../resources/images/slider/slider3.png'
import slider4 from '../../resources/images/slider/slider4.png'
import slider5 from '../../resources/images/slider/slider5.png'
import BackgroundImageOnLoad from 'background-image-on-load';

class Slider extends Component {

    constructor(props) {
        super(props);
        this.state = {loaded: 0};
    }

    render() {
        return (
            <Box>
                {this.state.loaded<4 ?
                    <CircularProgress style={{color: '#fbefea',
                        animationDuration: '550ms',
                        position: "absolute",
                        top: '270px',
                        left: 'calc(50% - 50px)'
                    }}
                                      size='100px'
                                      thickness={4}
                    /> : ""}
                <Box className="slider"  style={this.state.loaded===4 ? {opacity: 1, animation: 'showSlider 0.5s'} : {opacity: 0}}>

                    <div className="sliderImage1"><p className="sliderText4">Zadzwoń i zapytaj o szczegóły</p>
                        <BackgroundImageOnLoad src={slider4} onLoadBg={()=>this.setState({loaded: this.state.loaded+1})} onError={err => console.log('error', err)}/>
                    </div>
                    <div className="sliderImage2"><p className="sliderText3">Wieloletnie doświadcznie</p>
                        <BackgroundImageOnLoad src={slider2} onLoadBg={()=>this.setState({loaded: this.state.loaded+1})} onError={err => console.log('error', err)}/>
                    </div>
                    <div className="sliderImage3"><p className="sliderText2">Profesionalny sprzęt</p>
                        <BackgroundImageOnLoad src={slider5} onLoadBg={()=>this.setState({loaded: this.state.loaded+1})} onError={err => console.log('error', err)}/>
                    </div>
                    <div className="sliderImage4"><p className="sliderText1">Fachowa obsługa</p>
                        <BackgroundImageOnLoad src={slider3} onLoadBg={()=>this.setState({loaded: this.state.loaded+1})} onError={err => console.log('error', err)}/>
                    </div>
                </Box>
            </Box>
        )

    }
}

export default Slider;
