import cedrus_kw53 from './cedrus_kw53.jpg'
export const mowerCutters = [
    {
        "name": "Cedrus KW53",
        "url": "cedrus_kw53",
        "image_src": cedrus_kw53,
        "spec": [
            "Wymiary (dł. x szer. x wys.): 1855x730x480",
            "Max. moc wyjścia: 2,0 / 8500 kW/rpm",
            "Max. moment obrotowy: 3,0 / 5000 Nm/rpm",
            "Moc: 2kW / 2,72",
            "Mieszanka benzyna-olej: 50:1",
            "Uchwyt sterujący: podwójny typu rogi",
            "Szelki: podwójne",
            "Rozrusznik: ręczny - system KAR Start",
            "Pojemność zbiornika paliwa: 1,1 l",
            "Ciężar: 8,9 kg"
        ],
        "price_4h": 0,
        "price_1d": 120
    }
]
