import sarp_roto_404 from './sarp_roto_404.jpg'
export const tillers = [
    {
        "name": "PUBERT SARP ROTO 404",
        "url": "sarp_roto_404",
        "image_src": sarp_roto_404,
        "short_description": "",
        "spec": [
            "Zalecana powierzchnia pracy: 2500 m2 i więcej",
            "Moc: 5 KM",
            "Szerokość pracy: 48 cm",
            "Biegi: 1 do przodu / 1 do tyłu",
            "Prędkość na 1 biegu: 1.06 km/godz.",
            "Prędkość na biegu wstecznym: 0,67 km/godz.",
            "Koła traktorowe: 3.5x7 / fi320 mm",
            "Waga: 73 kg",
            "Kierownica składana, z regulowana wysokością",
            "4 japońskie spiralne zestawy noży (demontowanych) o średnicy 320 mm",
            "Miękkie uchwyty na kierownicy redukujące wibracje i poprawiające komfort pracy"
        ],
        "price_4h": 0,
        "price_1d": 100
    }
]
