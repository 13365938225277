import weibang_wbsc409h from './weibang_wbsc409h.jpg'
export const turfCutters = [
    {
        "name": "Weibang WBSC409H",
        "url": "weibang_wbsc409h",
        "image_src": weibang_wbsc409h,
        "short_description": "",
        "spec": [
            "Prędkość robocza: 0,6 – 0,8 km/h",
            "Szerokość robocza: 350 mm",
            "Max wydajność: 1000 m²/h",
            "Max moc silnika: 9 KM",
            "Max głębokość robocza: 50 mm",
            "Waga: 180 kg"
        ],
        "price_4h": 0,
        "price_1d": 250
    }
]
