import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import Header from "./shared/Header";
import Menu from "./shared/Menu";
import Slider from "./homepage/Slider";
import ServicesPanel from "./homepage/ServicesPanel";
import Footer from "./shared/Footer";

class Homepage extends Component {

    render() {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Header/>
                </Grid>
                <Grid item xs={12}>
                    <Menu/>
                </Grid>
                <Grid item xs={12}>
                    <Slider/>
                </Grid>
                <Grid item xs={12}>
                    <ServicesPanel/>
                </Grid>
                <Grid item xs={12}>
                    <Footer/>
                </Grid>
            </Grid>
        )

    }
}

export default Homepage;
