import React, {Component} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import CatalogMainMenu from "./components/CatalogMainMenu";
import Contact from "./components/Contact";
import Homepage from "./components/Homepage";
import {base_url, urls} from "./const/Urls";
import Catalog from "./components/Catalog";

class App extends Component {

    render() {
        return (
            <div className="site">
                <BrowserRouter>
                    <Switch>
                        <Route exact path={urls.contact}>
                            <Contact/>
                        </Route>
                        <Route exact path={urls.homepage}>
                            <Homepage/>
                        </Route>
                        <Route exact path={urls.catalog}>
                            <CatalogMainMenu/>
                        </Route>
                        <Route exact path={urls.catalog_construction}>
                            <Redirect to={urls.catalog_construction_first_category}/>
                        </Route>
                        <Route exact path={urls.catalog_garden}>
                            <Redirect to={urls.catalog_garden_first_category}/>
                        </Route>
                        <Route path={[urls.catalog_construction, urls.catalog_garden]} component={Catalog}/>
                        <Route path={urls.catalog}>
                            <Redirect to={urls.catalog}/>
                        </Route>
                        <Route path={base_url}>
                            <Redirect to={urls.homepage}/>
                        </Route>
                    </Switch>
                </BrowserRouter>
            </div>
        );
    }
}

export default App;
