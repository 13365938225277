import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import '../../resources/styles/catalog.scss'
import {Box} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import Divider from "@material-ui/core/Divider";

class ItemsList extends Component {

    render() {
        return (

            <Box className="itemsListContainer">
                <Grid container>
                    <Grid item xs={12}>
                        <Box className="itemDetailsHeader">
                            <span>{this.props.data.categories[this.props.location.pathname.split('/')[3]].name}</span>
                            <Divider style={{marginTop: "20px"}}/>
                        </Box>
                    </Grid>
                    {this.props.data.categories[this.props.location.pathname.split('/')[3]]
                        .catalog.map((item) =>
                            (
                                <Grid key={item.url} item xs={12} sm={5} md={3} lg={2} style={{margin: "20px"}}>
                                    <a href={window.location.href + '/' + item.url}>
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            style={{height: "100%" }}
                                        >
                                            <Grid item>
                                                <img className="itemsListItemImage" src={item.image_src} alt=""/>
                                            </Grid>
                                            <Grid item style={{marginTop: "10px"}}>
                                                {item.name}
                                            </Grid>
                                        </Grid>
                                    </a>
                                </Grid>
                            )
                        )}
                </Grid>
            </Box>

        )

    }
}

export default withRouter(ItemsList);
