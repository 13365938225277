import i1 from './1.jpg'
import i2 from './2.jpg'
export const scarifiers = [
    {
        "name": "Weibang WB384RB",
        "url": "weibang",
        "image_src": i1,
        "spec": [
            "Waga: 46 kg",
            "Moc silnika: 6KM",
            "Szerokość robocza: 38 cm",
            "Głębokość robocza: 0-1,6 cm",
            "Koła przednie/tylne: 200mm/200mm",
            "Zalecana powierzchnia pracy: do 2000 m2",
            "Składana regulowana do 2 pozycji kierownica",
            "Uchwyt bezpieczeństwa na kierownicy – zwolnienie uchwytu powoduje zatrzymanie maszyny",
            "Regulowana wysokość pracy",
            "22 zamontowanych noży uchylnych"
        ],
        "price_4h": 120,
        "price_1d": 200
    },
    {
        "name": "SARP SC50HPRO",
        "url": "sarp_sc50hpro",
        "image_src": i2,
        "spec": [
            "Moc silnika: 3,6kW",
            "Pojemność silnika: 163 cm3",
            "Szerokość robocza: 50 cm",
            "Głębokość robocza: 0-3 cm",
            "Zalecana powierzchnia pracy: do 2000 m2",
            "Waga: 65 kg"
        ],
        "price_4h": 120,
        "price_1d": 230
    }
]
