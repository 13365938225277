import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import '../../resources/styles/footer.scss'

class Footer extends Component {

    render() {
        return (
            <Box>
                <Box className="footerPanel">
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item xs={12} sm={6} md={6}>
                            <span className="footerPanelHeader">O nas</span>
                            <p className="footerPanelDescription">
                                KNOP-MAR jest firmą, która oferuje szeroki wachlarz usług z branży budowlanej i
                                ogrodniczej.<br/><br/>
                                Cechuje nas wieloletnie doświadczenie,
                                a tematyka elektronarzędzi oraz wyżej wymienionego sprzętu nie jest nas nam obca od
                                ponad
                                dziesięciu lat. Doświadczenie, wiedza, precyzja są dla nas znakiem rozpoznawczym.
                            </p>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <span className="footerPanelHeader">Kontakt</span>
                            <p className="footerPanelDescription">
                                <b>KNOP-MAR</b><br/>
                                Kudrowice 35G,<br/>
                                95-200 Pabiancie<br/>
                                +48 608341601<br/>
                                knop-mar@wp.pl
                            </p>
                        </Grid>
                    </Grid>
                </Box>
                <Box className="footerPanelCopyright">
                    <Grid container direction="row" justifyContent="center">
                        <Grid item xs={12}>
                        <span
                            className="footerPanelCopyrightText">
                            Strona nie używa plików cookie oraz nie zbiera żadnych danych o użytkownikach.
                            Copyright © 2021 KNOP-MAR. Wszelkie prawa zastrzeżone.
                        </span>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        )

    }
}

export default Footer;
