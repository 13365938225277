export const base_url=''
export const urls = {
    homepage: base_url + '/',
    catalog: base_url + '/katalog',
    contact: base_url + '/kontakt',
    catalog_garden: base_url + '/katalog/ogrodnicze',
    catalog_garden_first_category: base_url + '/katalog/ogrodnicze/glebogryzarki',
    catalog_construction: base_url + '/katalog/budowlane',
    catalog_construction_first_category: base_url + '/katalog/budowlane/agregaty_pradotworcze'
};


