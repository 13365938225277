import bomag from './bomag.jpg';
export const rollers = [
    {
        "name": "BOMAG BW 55 E",
        "url": "bomag_bw55e",
        "image_src": bomag,
        "spec": [
            "Masa: 150kg",
            "Wydajność: 2,5 kW",
            "Układ spryskiwaczy",
            "Drążek sterowniczy z tłumieniem drgań",
            "Drążek sterowniczy z regulacją wysokości",
            "Regulacja drgań i położenia przepustnicy na drążku",
            "Zgarniacze z przodu i z tyłu",
            "Podnośnik jednopunktowy",
            "Pręty wspornikowe z przodu i z tyłu",
            "Przyczepa transportowa w zestawie"
        ],
        "price_4h": 0,
        "price_1d": 150
    }
]
