import i1 from './1.jpg'

export const concreteMillingMachines = [
    {
        "name": "Frezarka do betonu",
        "url": "frezarka",
        "image_src": i1,
        "spec": [
            "Szerokość pracy: 200mm",
            "Silnik: elektryczny 2,2 kW",
            "Napięcie: 230V",
            "Frezarka może współpracować z odkurzaczem",
            "Siedmio stopniowa regulacja głębokości frezowania",
            "Masa własna: 55kg"
        ],
        "price_4h": 0,
        "price_1d": 300
    }
]
