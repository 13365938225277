import {tillers} from './garden/tillers/tillers.js'
import {mowerCutters} from "./garden/mowerCutters/mowerCutters";
import {pressureWashers} from "./garden/pressureWashers/pressureWashers";
import {sprayers} from "./garden/sprayers/sprayers";
import {chippers} from "./garden/chippers/chippers";
import {scarifiers} from "./garden/scarifiers/scarifiers";
import {soilDrillingRigs} from "./garden/soilDrillingRigs/soilDrillingRigs";
import {turfCutters} from "./garden/turfCutters/turfCutters";
import {excavators} from "./garden/excavators/excavators";
import {rollers} from "./garden/rollers/rollers";
import {generators} from "./construction/generators/generators";
import {concreteMixers} from "./construction/concreteMixers/concreteMixers";
import {concreteMillingMachines} from "./construction/concreteMillingMachines/concreteMillingMachines";
import {vibratingBars} from "./construction/vibratingBars/vibratingBars";
import {heaters} from "./construction/heaters/heaters";
import {IndustrialVacuumCleaners} from "./construction/IndustrialVacuumCleaners/IndustrialVacuumCleaners";
import {airDehumidifiers} from "./construction/airDehumidifiers/airDehumidifiers";
import {cutters} from "./construction/cutters/cutters";
import {vibratingJumpersAndFeet} from "./construction/vibratingJumpersAndFeet/vibratingJumpersAndFeet";
import {concreteVibrators} from "./construction/concreteVibrators/concreteVibrators";
import {compactors} from "./construction/compactors/compactors";
import {concreteFloats} from "./construction/concreteFloats/concreteFloats";
import {forgingHammers} from "./construction/forgingHammers/forgingHammers";
import {cableLayingMachines} from "./garden/cableLayingMachines/cableLayingMachines";

export const machines = {
    ogrodnicze: {
        name: "Maszyny ogrodnicze",
        categories: {
            glebogryzarki: {
                name: "Glebogryzarki",
                catalog: tillers
            },
            koparki: {
                name: "Koparki",
                catalog: excavators
            },
            kosy_kosiarki: {
                name: "Kosy & kosiarki",
                catalog: mowerCutters
            },
            maszyny_do_ukladania_przewodu: {
                name: "Maszyny do układania przewodu",
                catalog: cableLayingMachines
            },
            myjki_cisnieniowe: {
                name: "Myjki ciśnieniowe",
                catalog: pressureWashers
            },
            opryskiwacze: {
                name: "Opryskiwacze",
                catalog: sprayers
            },
            rebaki: {
                name: "Rębaki",
                catalog: chippers
            },
            walce: {
                name: "Walce",
                catalog: rollers
            },
            wertykulatory: {
                name: "Wertykulatory",
                catalog: scarifiers
            },
            wiertnice_glebowe: {
                name: "Wiertnice glebowe",
                catalog: soilDrillingRigs
            },
            wycinarki_do_darni: {
                name: "Wycinarki do darni",
                catalog: turfCutters
            }
        }
    },
    budowlane: {
        name: "Maszyny budowlane",
        categories:
            {
                agregaty_pradotworcze: {
                    name: "Agregaty prądotwórcze",
                    catalog: generators
                },
                betoniarki: {
                    name: "Betoniarki",
                    catalog: concreteMixers
                },
                frezarki_do_betonu: {
                    name: "Frezarki do betonu",
                    catalog: concreteMillingMachines
                },
                listwy_wibracyjne: {
                    name: "Listwy wibracyjne",
                    catalog: vibratingBars
                },
                mloty_do_klucia: {
                    name: "Młoty do kucia",
                    catalog: forgingHammers
                },
                nagrzewnice: {
                    name: "Nagrzewnice",
                    catalog: heaters
                },
                odkurzacze_przemyslowe: {
                    name: "Odkurzacze przemysłowe",
                    catalog: IndustrialVacuumCleaners
                },
                osuszacze_powietrza: {
                    name: "Osuszacze powietrza",
                    catalog: airDehumidifiers
                },
                przecinarki: {
                    name: "Przecinarki",
                    catalog: cutters
                },
                skoczki_stopy_wibracyjne: {
                    name: "Skoczki/stopy wibracyjne",
                    catalog: vibratingJumpersAndFeet
                },
                wibratory_do_betonu: {
                    name: "Wibratory do betonu",
                    catalog: concreteVibrators
                },
                zacieraczki_do_betonu: {
                    name: "Zacieraczki do betonu",
                    catalog: concreteFloats
                },
                zageszczarki: {
                    name: "Zagęszczarki",
                    catalog: compactors
                }
            }
    }
}
