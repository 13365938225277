import React, {Component} from 'react';
import {urls} from "../../const/Urls";
import '../../resources/styles/header.scss'
import {Box} from "@material-ui/core";

class Header extends Component {

    render() {
        return (
            <Box className="headerBox">
                <a href={urls.homepage} className="headerCompanyName">KNOP-MAR</a><br/>
                <span className="headerDescription">Wypożyczalnia maszyn budowlanych i ogrodniczych</span>
            </Box>
        )

    }
}

export default Header;
