import i1 from './1.jpg'
import i2 from './2.jpg'
import cm12y from './cm12y.jpg'
export const cutters = [
    {
        "name": "Spalinowa ręczna 400mm",
        "url": "makita_400",
        "image_src": i2,
        "spec": ["Lekka konstrukcja",
            "Duży i łatwo dostępny filtr powietrza",
            "Bezstopniowe przestawianie osłony tarczy",
            "Podstawa z kołami jako wyposażenie dodatkowe",
            "Elektroniczne ograniczenie obrotów maksymalnych",
            "Zawór dekompresyjny ułatwiający rozruch",
            "Silnik: 2-suwowy 81cm3",
            "Moc: 4,5kW (6,1KM)",
            "Poj. zbiornika paliwa: 1,1L",
            "Średn. ściernicy/otworu: 400mm/25,4mm",
            "Max. głębokość cięcia: 145mm",
            "Prędkość obwodowa: 80m/s",
            "Ciężar bez tarczy: 10,9kg"],
        "price_4h": 0,
        "price_1d": 120
    },
    {
        "name": "Spalinowa Jezdna 500mm",
        "url": "jezdna_500",
        "image_src": i1,
        "spec": ["Piła do cięcia asfaltu i betonu",
            "Wyposażona w chłodzenie wodne",
            "Silnik: HONDA GX 390",
            "Waga: 120kg",
            "Głębokość cięcia: 150mm",
            "Średnica tarczy: 500mm",
            "Max. głębokość cięcia: 145mm"],
        "price_4h": 0,
        "price_1d": 120
    },
    {
        "name": "Elektryczna ręczna Hitachi CM12Y UA",
        "url": "hitachi_cm12y",
        "image_src": cm12y,
        "spec": ["Typ zasilania: elektryczne",
            "Moc: 2400 W",
            "Prędkość obrotowa: 5000 rpm",
            "Średnica tarczy: 2400 mm",
            "Średnica otworu tarczy: 22,23 mm",
            "Maks. głębokość cięcia: 100 mm",
            "Sposób cięcia: na sucho",
            "Masa: 11,5 kg"],
        "price_4h": 0,
        "price_1d": 80
    }
]
