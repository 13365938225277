import barreto_712mth from './barreto_712mth.jpg';
export const excavators = [
    {
        "name": "BARRETO 712MTH",
        "url": "barreto",
        "image_src": barreto_712mth,
        "spec": [
            "Moc Silnika (KM): 5.5",
            "Pojemność Zbiornika Oleju (L): 0.6",
            "Pojemność Zbiornika Paliwa (L): 3.1",
            "Pojemność Zbiornika Hydraulicznego (L): 5.68",
            "Rozruch: Ręczny", "Napęd: Tak - Na Tylne Koła",
            "Prędkość Jazdy Przód / Tył (M/Min): 45 / 7",
            "Wymiary Dł. / Szer. / Wys. (Cm): 181 / 77.4 / 109",
            "Głębokość Kopania (Cm): 45",
            "Prędkość Łańcucha Podczas Pracy (M/Min): 60.96",
            "Rozstaw Kół (Cm): 64.1",
            "Waga (Kg): 181.5"
        ],
        "price_4h": 0,
        "price_1d": 350
    }
]
