import i1 from './1.jpg'

export const concreteFloats = [
    {
        "name": "Zacieraczka do betonu - 600mm",
        "url": "600mm",
        "image_src": i1,
        "spec": [    "Średnica robocza: 600 mm",    "Wydajność m2/h: ~ 40",    "Waga bez tarczy: 40 kg",    "Napęd: 1,5 kW",    "Obroty robocze: 110 1/min",    "W zestawie talerz oraz łopaty"],
        "price_4h": 0,
        "price_1d": 120
    },
    {
        "name": "Zacieraczka do betonu - 900mm",
        "url": "900mm",
        "image_src": i1,
        "spec": [    "Średnica robocza: 900 mm", "W zestawie talerz oraz łopaty"],
        "price_4h": 0,
        "price_1d": 170
    }
]
