import React, {Component} from 'react';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import '../../resources/styles/servicesPanel.scss'

class ServicesPanel extends Component {

    render() {
        return (
            <Box className="servicesPanel">
                <Grid container direction="row" justifyContent="center">
                    <Grid item sm={4} xs={12}>
                        <Box className="box, servicesPanelItem">
                            <span className="servicesPanelHeader">Wypożyczalnia</span><br/>
                            <span className="servicesPanelDescription">Wynajmujemy sprzęt budowlany i ogrodniczy</span>
                        </Box>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Box className="box, servicesPanelItem">
                            <span className="servicesPanelHeader">Usługi</span><br/>
                            <span className="servicesPanelDescription">Świadczymy usługi na sprzęcie budowlanym i
                                ogrodniczym</span>
                        </Box>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Box className="box, servicesPanelItem">
                            <span className="servicesPanelHeader">Serwis</span><br/>
                            <span
                                className="servicesPanelDescription">Naprawiamy elektronarzędzia i sprzęt budowlany</span>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )

    }
}

export default ServicesPanel;
