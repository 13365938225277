import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import Header from "./shared/Header";
import Menu from "./shared/Menu";
import Footer from "./shared/Footer";
import '../resources/styles/catalog.scss'
import {urls} from "../const/Urls";
import LeftMenu from "./catalog/LeftMenu";
import ItemsList from "./catalog/ItemsList";
import {machines} from "../resources/config/machines";
import Box from "@material-ui/core/Box";
import {withRouter} from "react-router-dom";
import ItemDetails from "./catalog/ItemDetails";

class Catalog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null, //all data for category
        }
    }

    componentDidMount() {
        this.checkUrl(this.loadData());
    }

    checkUrl(state) {
        let path = this.props.location.pathname.split('/')
        if (path.length >= 6) {
            this.props.history.push(state.baseUrl);
        } else {
            if (path.length === 5) {
                let machine = state.data.categories[path[3]].catalog.find(item => item.url === path[4])
                if (!machine) {
                    this.props.history.push(state.baseUrl + "/" + path[3]);
                }
            }
            let category = state.data.categories[path[3]];
            if (!category) {
                this.props.history.push(state.baseUrl);
            }
        }
    }

    loadData() {
        let type = this.props.location.pathname.split('/')[2];
        let state = {
            data: machines[type],
            baseUrl: urls.catalog + "/" + type
        }
        this.setState({...state})
        return state;
    }

    render() {
        if (this.state.data !== null) {
            return (
                <Grid container>
                    <Grid item xs={12}>
                        <Header/>
                    </Grid>
                    <Grid item xs={12}>
                        <Menu/>
                    </Grid>
                    <Grid item xs={12} sm={5} md={4} lg={3}>
                        <LeftMenu {...this.props} data={this.state.data} baseUrl={this.state.baseUrl}
                        />
                    </Grid>
                    <Grid item xs={12} sm={7} md={8} lg={9}>
                        {this.props.location.pathname.split('/').length === 5 ?
                            <ItemDetails {...this.props} data={this.state.data}/> :
                            <ItemsList {...this.props} data={this.state.data}/>}
                    </Grid>
                    <Grid item xs={12}>
                        <Footer/>
                    </Grid>
                </Grid>
            )
        } else {
            return (
                <Grid container>
                    <Grid item xs={12}>
                        <Header/>
                    </Grid>
                    <Grid item xs={12}>
                        <Menu/>
                    </Grid>
                    <Grid item xs={12}>
                        <Box>404 - Nie znaleziono strony</Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Footer/>
                    </Grid>
                </Grid>
            )
        }
    }


}

export default withRouter(Catalog);
