import i1 from './1.jpg'
export const concreteMixers = [
    {
        "name": "Elektryczna - Agrowikt",
        "url": "agrowikt",
        "image_src": i1,
        "spec": [
            "wysokość: 1450 mm",
            "szerokość: 670 mm",
            "długość min: 1400 mm",
            "długość max: 1900 mm",
            "Wysokość załadunku: 1100 mm",
            "Wysokość wyładunku: 520 mm",
            "Pojemność całkowita: 160 dm3",
            "Pojemność robocza: 110 dm3",
            "Średnica zewnętrzna bębna: 600 mm",
            "Średnica otworu zasyp.: 350 mm",
            "Obroty bębna betoniarki: 28 obr/min",
            "Wydajność: 1,5 m3/h",
            "Masa własna: 120 kg",
            "Typ silnika: elektryczny",
            "Moc silnika: 1,1 kW",
            "Obroty silnika: 1415 obr/min",
            "Napięcie zasilające: 230 V"
        ],
        "price_4h": 0,
        "price_1d": 50
    }
]
