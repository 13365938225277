import h45mey from './h45mey.jpg';
import h60mc from './h60mc.jpg';
import h90sg from './h90sg.jpg';
import makita_HM1203C from './makita_HM1203C.jpg';
export const forgingHammers = [
    {
        "name": "Hikoki SDS H45MEY WTZ",
        "url": "h45mey",
        "image_src": h45mey,
        "short_description": "",
        "spec": [
            "Moc (W): 1150",
            "Energia pojedynczego udaru (J): 8,5",
            "Liczba udarów (1/min): 1320-2850",
            "Dane fizyczne: Długość całkowita (mm): 480",
            "Waga (kg): 7",
            "Napięcie elektryczne (V): 230",
            "Wyposażenie: Walizka transportowa: tak",
            "Rękojeść boczna: tak",
            "Obudowa soft grip: tak"
        ],
        "price_4h": 0,
        "price_1d": 70
    },
    {
        "name": "Hikoki SDS-MAX H60MC",
        "url": "hikoki_H60MC",
        "image_src": h60mc,
        "short_description": "",
        "spec": [
            "Moc (W): 1250",
            "Energia pojedynczego udaru (J): 14",
            "Liczba udarów (1/min): 1970",
            "Waga (kg): 10,2",
            "Napięcie elektryczne (V): 230",
            "Walizka transportowa: tak",
            "Rękojeść boczna: tak"
        ],
        "price_4h": 0,
        "price_1d": 80
    },
    {
        "name": "Hikoki H65SB3 WTZ",
        "url": "h65sb3",
        "image_src": makita_HM1203C,
        "spec": [
            "Moc (W): 1340",
            "Energia pojedynczego udaru (J): 45",
            "Liczba udarów (1/min): 1400",
            "Dane fizyczne: Waga (kg): 18",
            "Uchwyt roboczy (mm/cale): 30 (1-3/16\")",
            "Napięcie elektryczne (V): 230",
            " Walizka transportowa: tak",
            "Rękojeść boczna: tak",
            "Obudowa soft grip: tak"
        ],
        "price_4h": 0,
        "price_1d": 100
    },
         {
             "name": "Hikoki H90SG",
             "url": "h90sg",
             "image_src": h90sg,
             "spec": [
                "Moc (W): 2000",
                "Energia pojedynczego udaru (J): 57,7",
                "Długość całkowita (mm): 860",
                "Waga (kg): 32",
                "Uchwyt roboczy (mm/cale): 28 (1-1/8\")",
                "Napięcie elektryczne (V): 230",
                "Walizka transportowa: nie",
                "Obudowa soft grip: tak"
             ],
             "price_4h": 0,
             "price_1d": 150
         }
]
