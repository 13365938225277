import React from 'react';
import Grid from "@material-ui/core/Grid";
import '../../resources/styles/catalog.scss'
import {Box, Menu} from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import MenuIcon from '@material-ui/icons/Menu';
import Button from "@material-ui/core/Button";
import MuiMenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";


export default function LeftMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        document.documentElement.style.overflow = 'hidden';
        document.body.scroll = "no";
    };

    const handleClose = () => {
        setAnchorEl(null);
        document.documentElement.style.overflow = 'hidden';
        document.body.scroll = "yes";

    };

    const MenuItem = withStyles({
        root: {
            justifyContent: "center"
        }
    })(MuiMenuItem);

    return (
        <Box>
            <Hidden xsDown={true}>
                <Box className="catalogLeftMenuContainer">
                    <Grid container>
                        <Grid item xs={12}>
                            <Box className="catalogLeftMenuHeader">
                                {props.data.name}
                            </Box>
                        </Grid>
                        {Object.keys(props.data.categories).map((itemKey) => (
                            props.location.pathname.split('/')[3] === itemKey ? (
                                <Grid item xs={12} className="catalogLeftMenuItem activeCategory" key={itemKey}>
                                    <a href={props.baseUrl + "/" + itemKey}>
                                        <Box>
                                            {props.data.categories[itemKey].name}
                                        </Box>
                                    </a>
                                </Grid>
                                ):
                                (
                                    <Grid item xs={12} className="catalogLeftMenuItem" key={itemKey}>
                                        <a href={props.baseUrl + "/" + itemKey}>
                                            <Box>
                                                {props.data.categories[itemKey].name}
                                            </Box>
                                        </a>
                                    </Grid>
                                )
                        ))
                        }
                    </Grid>
                </Box>
            </Hidden>
            <Hidden smUp={true}>
                <Box className="catalogMobileMenuContainer">
                            <Button
                                variant="contained"
                                color="grey"
                                className="catalogMobileButton"
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                                startIcon={<MenuIcon/>}
                            >{props.data.name}</Button>
                            <Menu
                                elevation={0}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                PaperProps={{
                                    style: {
                                        width: '100%',
                                    }, }}
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                {Object.keys(props.data.categories).map((itemKey) => (
                                    <MenuItem key={itemKey}  onClick={handleClose}><a href={props.baseUrl + "/" + itemKey}
                                    >{props.data.categories[itemKey].name}</a></MenuItem>
                                ))
                                }
                            </Menu>
                </Box>
            </Hidden>
        </Box>
    )

}

