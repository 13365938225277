import flex_s44lac from './flex_s44lac.jpg'
export const IndustrialVacuumCleaners = [
    {
        "name": "FLEX S44LAC",
        "url": "flex",
        "image_src": flex_s44lac,
        "spec": ["Moc: 1400 W",
            "Maksymalna wydajność powietrza: 4500 l/min",
            "Maksymalne podciśnienie: 25000 Pa (250 mbar)",
            "Powierzchnia filtra: 5.000 cm2",
            "Pojemność zbiornika: 42 l",
            "Pojemność cieczy: 26 l",
            "Gniazdo podłączeniowe: 100 - 2400 W",
            "Długość: 565 mm",
            "Szerokość: 385 mm",
            "Wysokość: 650 mm",
            "Długość kabla: 7,5 m",
            "Waga: 16,7 kg"],
        "price_4h": 0,
        "price_1d": 60
    }
]
