import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import '../../resources/styles/catalog.scss'
import {Box} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Divider from "@material-ui/core/Divider";

class ItemDetails extends Component {

    renderPriceElement(duration, price){
            return (<p><span className="itemDetailsPriceName">Cena wynajmu ({duration}): </span><span className="itemDetailsPriceValue">{price} zł</span></p>)
        }

    render() {
        let path = this.props.location.pathname.split('/');
        let item = this.props.data.categories[path[3]].catalog.find(item => item.url === path[4]);
        return (
            <Box className="itemDetailsContainer">
                <Grid container direction="row"
                      justifyContent="space-around" style={{padding: "20px"}}>
                    <Grid item xs={12}>
                        <Box className="itemDetailsHeader">
                            <span>{item && item.name}</span>
                            <Divider style={{marginTop: "20px"}}/>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Grid container>
                            <Grid item xs={12}>
                                <img className="itemDetailsImage" src={item.image_src} alt=""/>
                            </Grid>
                            <Grid item xs={12}>
                                {item && item.price_4h?this.renderPriceElement('4 godziny',item.price_4h):""}
                                {item && item.price_1d?this.renderPriceElement('1 dzień',item.price_1d):""}
                                {item && (item.price_4h || item.price_1d)? <p><span className="itemDetailsPriceDetails">W przypadku dłuższych okresów wynajmu cena podlega negocjacji.</span></p>:""}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        {item && item.specs ? item.specs.map((specsItem, key) => (
                                <TableContainer className="itemDetails" key={key}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" colSpan={2}>
                                                    <span className="itemDetailsSpecsName">{specsItem.name}</span>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {specsItem.data.map((row, key) => (
                                                <TableRow key={key}>
                                                    <TableCell component="th" scope="row">
                                                        {row.split(':')[0]}
                                                    </TableCell>
                                                    <TableCell align="right">{row.split(':')[1]}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )) :

                            (<TableContainer className="itemDetails">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" colSpan={2}>
                                                <span className="itemDetailsSpecsName">Dane techniczne</span>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {item && item.spec.map(row => (
                                            <TableRow key={row.split(':')[0]}>
                                                <TableCell component="th" scope="row">
                                                    {row.split(':')[0]}
                                                </TableCell>
                                                <TableCell align="right">{row.split(':')[1]}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>)
                        }
                    </Grid>
                </Grid>
            </Box>
        )

    }
}

export default withRouter(ItemDetails);
