import ecotech_nt60 from './ecotech_nt60.jpg'

export const cableLayingMachines=[
    {
        "name": " Ecotech NT60",
        "url": "ecotech_nt60",
        "image_src": ecotech_nt60,
        "spec": [
            "Producent silnika: Honda",
            "Model silnika: GCV160",
            "Pojemność zbiornika paliwa: 1,1l",
            "Pojemność miski olejowej: 0,6l",
            "Wydajność: 36m/min",
            "Głębokość układania przewodu: 1 do 6cm",
            "Max. średnica przewodu: 5mm",
            "Min. odległość od przeszkody: 250mm",
            "Napęd: tak",
            "Nóż nacinający: wiertło",
            "Regulowany uchwyt: tak",
            "Regulowany rozstaw kół: tak",
            "Waga maszyny: 45 kg"
        ],
        "price_4h": 0,
        "price_1d": 170
    }
]
