import ralf from './ralf.jpg'

export const airDehumidifiers = [
    {
        "name": "90L",
        "url": "90l",
        "image_src": ralf,
        "spec": [
            "Wydajność: 90 litrów/doba",
            "Zasilanie: 230 V",
            "Automatyczy odpływ wody"
        ],
        "price_4h": 0,
        "price_1d": 50
    },
    {
        "name": "138L",
        "url": "138l",
        "image_src": ralf,
        "spec": [
            "Wydajność: 138 litrów/doba",
            "Zasilanie: 230 V",
            "Automatyczy odpływ wody"
        ],
        "price_4h": 0,
        "price_1d": 60
    }
]
