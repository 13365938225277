import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import Header from "./shared/Header";
import Menu from "./shared/Menu";
import Box from "@material-ui/core/Box";
import Footer from "./shared/Footer";
import '../resources/styles/catalog.scss'
import construction_image from "../resources/images/catalog/construction.jpg"
import garden_image from "../resources/images/catalog/garden.jpg"
import {urls} from "../const/Urls";

class CatalogMainMenu extends Component {

    render() {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Header/>
                </Grid>
                <Grid item xs={12}>
                    <Menu/>
                </Grid>
                <Grid item xs={12} style={{padding: "20px 0 50px 0"}}>
                    <Grid container
                          direction="row"
                          justifyContent="center"
                          alignItems="center">
                        <Grid item xs={12}>
                            <Box className="bodyHeader">
                                <span>Wybierz rodzaj maszyn:</span>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={5} md={4}>
                            <a href={urls.catalog_garden_first_category}>
                                <Box className="catalogItem">
                                    <img src={garden_image} alt="Maszyny ogrodniczne" style={{maxWidth: "70%"}}/><br/>
                                    <span>Ogrodnicze</span>
                                </Box>
                            </a>
                        </Grid>
                        <Grid item xs={6} sm={5} md={4}>
                            <a href={urls.catalog_construction_first_category}>
                                <Box className="catalogItem">
                                    <img src={construction_image} alt="Maszyny budowlane" style={{maxWidth: "70%"}}/><br/>
                                    <span>Budowlane</span>
                                </Box>
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Footer/>
                </Grid>
            </Grid>
        )

    }
}

export default CatalogMainMenu;
