import i1 from './1.jpg'
export const pressureWashers = [
    {
        "name": "Kranzle Profi 160",
        "url": "kranzle_profi_160",
        "image_src": i1,
        "spec": [
            "Ciśnienie robocze regulowane bezstopniowo: 30-140 bar",
            "Maks. dozwolone nadciśnienie: 160 bar",
            "Wydajność wody: 11 l/min (660 l/h)",
            "Elektryczna moc przyłączowa: 230 V, 14,3 A, 50 Hz",
            "Pobór mocy / moc użyteczna: 3,2 kW / 2,5 kW",
            "Masa: 45 kg"
        ],
        "additional_equipment": [
            "szczotka myjąca do kostki brukowej",
            "siewnik do trawy",
            "walec do trawy"
        ],
        "price_4h": 0,
        "price_1d": 100
    }
]
